import { createGlobalStyle } from "styled-components";

const Typography = createGlobalStyle`

  html {
    font-family: "Quicksand", "Open Sans", "Helvetica Neue", sans-serif;
    color: var(--black);
  }

  body {
    letter-spacing: 0.2px;
    font-size: 1.8rem;
  }

  h1,h2,h3,h4,h5,h6 {
    font-weight: normal;
    margin: 0;
  }

  a {
    color: var(--black);
  }

`;

export default Typography;
