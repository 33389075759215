/* eslint-disable */
import React from "react";
import { Route, Switch } from "react-router-dom";
import Flip from "./components/Flip";
import Home from "./components/Home";
import Layout from "./components/Layout";

// import socketIOClient from "socket.io-client";

const ENDPOINT = "http://127.0.0.1:4001";


function App() {

  // const [ response, setResponse ] = useState("");

  // useEffect(() => {

  //   const socket = socketIOClient(ENDPOINT, { query: { gameId: 1 } });

  //   console.log("setting socket");
    

  //   socket.on("FromAPI", data => {

  //     setResponse(data);
    
  //   });
  
  // }, []);


  // const sendTest = async () => {

  //   const res = await fetch(`${ENDPOINT}/start`);

  //   const text = JSON.parse(await res.text());

  //   // eslint-disable-next-line
  //   console.log("response", text);

  // };

  return (
    <Layout>
      <Switch>
        <Route path="/" component={Home} exact />
        <Route path="/flip/:gameId" component={Flip} />
      </Switch>
    </Layout>
  );

}

export default App;
