import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  :root {
    --red: #FF4949;
    --black: #2E2E2E;
    --white: #fff;
    --grey: #efefef;
    --themeColour: #aba104;
    --borderRadius: 4px;
  }
  html {
    font-size: 10px;
    background: var(--grey);
  }

  img {
    max-width: 100%;
  }
`;

export default GlobalStyles;
