import "normalize.css";
import GlobalStyles from "../styles/GlobalStyles";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import Typography from "../styles/Typography";

const SiteGridStyles = styled.div`

  max-width: 1400px;
  margin: 0 auto;
  background-color: white;

`;

const Layout = ({ children }) => (
  <>
    <GlobalStyles />
    <Typography />
    <SiteGridStyles>
      {children}
    </SiteGridStyles>
  </>
);

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};

export default Layout;
