/* eslint-disable */
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";

const CoinFlipGrid = styled.div`

  display: grid;
  padding: 2rem;
  grid-template-columns: 300px 1fr 300px;
  h1 {
    grid-column: span 3;
    text-align: center;
    margin-bottom: 4rem;
    span {
      background-color: red;
      display: inline-block;
      transform: rotate()(-2deg);
      color: white;
      padding: 5px 15px;
    }
  }
`;

const PlayerStyles = styled.div`

  span {
    font-size: 1rem;
    &:before {
      content: "(";
    }
    &:after {
      content: ")";
    }
  }

  h2 {
    margin-bottom: 2rem;
  }

  .winner, .loser {

    font-size: 3rem;
    margin-top: 2rem;
    color: white;
    padding: 2rem;
    text-align: center;

  }

  .winner {
    background-color: green;
  }
  .loser {
    background-color: red;
  }

  .play-again {
    color: blue;
    cursor: pointer;
    margin-top: 2rem;
    text-decoration: underline;
    &[disabled] {
      pointer-events: none;
      color: grey;
      opacity: 0.5;
    }
  }

  .play-again-message {
    margin-top: 2rem;
  }

  .all-wins {
    margin-top: 2rem;
  }

`;

const tossHeads = keyframes`
  from {
    transform: rotateY(0deg);
  }

  to {
    transform: rotateY(1800deg);
  }
`;

const tossTails = keyframes`
  from {
    transform: rotateY(0deg);
  }

  to {
    transform: rotateY(1620deg);
  }
`;

const CoinStyles = styled.div`

  width: 50%;
  height: 0;
  padding-top: 50%;
  margin: 0 auto;
  transform-style: preserve-3d;
  position: relative;

  &.animateHeads {
    animation: ${tossHeads} 3s;
    animation-fill-mode: forwards;
  }
  &.animateTails {
    animation: ${tossTails} 3s;
    animation-fill-mode: forwards;
  }


`;

const CoinStylesHeads = styled.div`

  width: 100%;
  height: 100%;
  border-radius: 50%;
  backface-visibility: hidden;
  background-image: url("https://miro.medium.com/max/390/1*AbCy6_7p6_gecetSMQgqDA.png");
  background-size: cover;
  position: absolute;
  top: 0;
`;

const CoinStylesTails = styled(CoinStylesHeads)`
  background-image: url("https://miro.medium.com/max/390/1*zB-fSZE3emczkxmpuOlFgg.png");
  transform: rotateY(-180deg);
`;


const ControlStyles = styled.div`

  text-align: left;

  button {
    display: block;
    border: 1px solid red;
    margin: 1rem 0;
    width: 100%;
    background-color: white;
    padding: 1rem 4rem;
    cursor: pointer;
  }

`;

const CoinFlipOptions = ( {handleClick} ) => {

return (
  <ControlStyles>
    <h3>You choose</h3>
    <button onClick={() => handleClick("heads")}>Heads</button>
    <button onClick={() => handleClick("tails")}>Tails</button>
  </ControlStyles>
)

};


const CoinFlip = ({ 
  allResults, 
  playerToPick, 
  position, 
  socket, 
  rematchRequestedBy,
  result, 
  winner }) => {

  const [ tossClass, setTossClass ] = useState("");
  const [ displayWinner, setDisplayWinner ] = useState(false);
  const [ selectionMade, setSelectionMade ] = useState(false);
  const [ playerOneSelection, setPlayerOneSelection ] = useState(null);
  const [ playerTwoSelection, setPlayerTwoSelection ] = useState(null);

  useEffect(() => {

    if (result != null) {

      const className = result === "heads" ? "animateHeads" : "animateTails";
      setTossClass(className);
      setSelectionMade(true);

    } else {
      setTossClass("");
      setSelectionMade(false);
    }

  }, [ result ]);

  useEffect(() => {

    if (winner != null) {

      const p1Selection = winner == 1 ? result : displayOpposite(result);
      const p2Selection = winner == 2 ? result : displayOpposite(result);

      setPlayerOneSelection(p1Selection);
      setPlayerTwoSelection(p2Selection);

      setTimeout(() => {

        setDisplayWinner(true);

      }, 4000);

    } else {

      setPlayerOneSelection(null);
      setPlayerTwoSelection(null);
      setDisplayWinner(false);

    }

  }, [ winner ]);

  const handleClick = choice => {

    socket.emit("make-selection", position, choice);

  };

  const requestRematch = () => {

    console.log("requesting rematch...");
    

    socket.emit("request-rematch", position);

  }

  const displayOpposite = side => {

    return side == "heads" ? "tails" : "heads";

  }
  

  return (
    <>
      <CoinFlipGrid>

        <h1><span>Lets play</span></h1>
        <PlayerStyles>
          <h2>Player 1 { position == 1 && ( <span>That's you</span> ) }</h2>
          { position == 1 && playerToPick == position && !selectionMade && (
            <CoinFlipOptions handleClick={handleClick} />
          )}
          { position == 1 && playerToPick != position && !selectionMade && (
            <div>Waiting for opponent to pick</div>
          )}
          { selectionMade && (
            <div>{ playerOneSelection }</div>
          )}
          { displayWinner && (
            <>
              { allResults.length > 1 && (
                <div className="all-wins">
                  Total Wins: { allResults.filter(winner => winner == 1).length }
                </div>
              )}
              <div className={ playerOneSelection == result ? "winner" : "loser" }>
                { playerOneSelection == result ? "Winner" : "Loser" }!
              </div>
              { position == 1 && (
                <div className="play-again" onClick={() => requestRematch()} role="button" disabled={rematchRequestedBy == 1}>
                  Play again?
                </div>
              )}
              { position == 2 && rematchRequestedBy == 1 && (
                <div className="play-again-message">
                  Rematch requested
                </div>
              )}
              { position == 2 && rematchRequestedBy == 2 && (
                <div className="play-again-message">
                  Waiting to accept
                </div>
              )}

            </>
          )}
        </PlayerStyles>
        <div>
          <CoinStyles className={tossClass}>
            <CoinStylesHeads />
            <CoinStylesTails />
          </CoinStyles>
        </div>
        <PlayerStyles>
          <h2>Player 2  { position == 2 && ( <span>That's you</span> ) }</h2>
          { position == 2 && playerToPick == position && !selectionMade && (
            <CoinFlipOptions handleClick={handleClick} />
          )}
          { position == 2 && playerToPick != position && !selectionMade && (
            <div>Waiting for opponent to make selection</div>
          )}
          { selectionMade && (
            <div>{ playerTwoSelection }</div>
          )}
          { displayWinner && (
            <>
              { allResults.length > 1 && (
                <div className="all-wins">
                  Total Wins: { allResults.filter(winner => winner == 2).length }
                </div>
              )}
              <div className={ playerTwoSelection == result ? "winner" : "loser" }>
                { playerTwoSelection == result ? "Winner" : "Loser" }!
              </div>
              { position == 2 && (
                <div className="play-again" onClick={() => requestRematch()} role="button" disabled={rematchRequestedBy == 2}>
                  Play again?
                </div>
              )}
              { position == 1 && rematchRequestedBy == 2 && (
                <div className="play-again-message">
                  Rematch requested
                </div>
              )}
              { position == 1 && rematchRequestedBy == 1 && (
                <div className="play-again-message">
                  Waiting to accept
                </div>
              )}
            </>
          )}
        </PlayerStyles>

      </CoinFlipGrid>
    </>  
  );

};

CoinFlip.propTypes = { 
  allResults: PropTypes.any,
  playerToPick: PropTypes.number.isRequired, 
  position: PropTypes.number.isRequired,
  socket: PropTypes.any,
  rematchRequestedBy: PropTypes.number,
  result: PropTypes.string, 
  winner: PropTypes.number 
};


export default CoinFlip;
