/* eslint-disable */
import CoinFlip from "./CoinFlip";
import React, { useEffect, useState } from "react";
import socketIOClient from "socket.io-client";
import styled from "styled-components";

const ErrorMessageStyles = styled.div`
  padding: 2rem;
  color: red;
  font-size: 4rem;
`;

const WaitingForPlayers = () => (
  <div>Waiting for players</div>
)

const ENDPOINT = "https://server.toss4.it/";

const Flip = ({ match }) => {
  
  const [ playerPosition, setPlayerPosition ] = useState(null);
  const [ playerToPick, setPlayerToPick ] = useState(null);
  const [ connectedPlayers, setConnectedPlayers ] = useState(0);
  const [ watchers, setWatchers ] = useState(0);
  const [ gameId, setGameId ] = useState();
  const [ playerName, setPlayerName ] = useState("");
  const [ errorMessage, setErrorMessage ] = useState(null);
  const [ socketId, setSocketId ] = useState(null);
  const [ socket, setSocket ] = useState(null);
  const [ result, setResult ] = useState(null);
  const [ allResults, setAllResults ] = useState([]);
  const [ winner, setWinner ] = useState(null);
  const [ rematchRequestedBy, setRematchRequestedBy ] = useState(null);

  useEffect(() => {

    setGameId(match.params.gameId);

      // todo set player name form local storage if available
      setPlayerName("");
  
  }, []);


  useEffect(() => {

    if (gameId != null) {
      
      setSocket(socketIOClient(ENDPOINT, {
          reconnection: true,
          reconnectionAttempts: 10,
          reconnectionDelay: 500,
          reconnectionDelayMax: 5000,
          randomizationFactor: 0.5,
          query: { gameId }
      }));

    }
  
  }, [ gameId ]);

  useEffect(() => {

    if (socket != null) {

      console.log("socket", socket);
      socket.emit("new-user", playerName);

      socket.on("socket-id", id => {
        console.log("socket-id", id);
        setSocketId(id);
      });

      socket.on("connect", () => {

        console.log("reconnected", playerPosition);

      });


      socket.on("connected-players", data => {

        console.log("connected-players", data);
        
        setConnectedPlayers(data);

      });

      socket.on("set-player-position", position => {

          console.log("set-player-position", position);
          
          setPlayerPosition(position);

      });

      socket.on("watcher-connected", data => {

        console.log("watcher-connected", data);
        setWatchers(data);

      })

      socket.on("max-players-reached", () => {

        console.log("max-players-reached");
        // if reconnected and already assigned a position we do not want to display the error
        if (playerPosition == null) {
          setErrorMessage("Game in progress");
        }

      });

      socket.on("start-game", playerToStart => {

        console.log("start-game", playerToStart);
        setPlayerToPick(playerToStart);
      
      })

      socket.on("coin-tossed", (result, winner, allResults) => {

        setResult(result);
        setWinner(winner);
        setAllResults(allResults);

      });

      socket.on("rematch-requested", player => {

        console.log("rematch-requested", player);
        setRematchRequestedBy(player);

          
      })

      socket.on("restart-match", playerToStart => {

        console.log("restart-match", playerToStart);
        

        setPlayerToPick(playerToStart);
        
        setResult(null);
        setWinner(null);
        setRematchRequestedBy(null);
          
      })

    }

  }, [ socket ])

  return (
    <div>
      { errorMessage && (
        <ErrorMessageStyles>{ errorMessage }</ErrorMessageStyles>
      )}
      {/*
        { gameId && (
          <>Game Id: { gameId } </>
        )}
        <>Users connected: { connectedPlayers }</>
        <>Watchers: { watchers }</>
        { players && (
          <>We have { players.length } players ready...</>
        )}
        { socketId && (
          <>My ID: { socketId }</>
        )}
        { playerPosition && (
          <>My position: { playerPosition }</>
        )}
      */}

      { playerPosition != null && playerToPick != null && (
        <CoinFlip 
          allResults={allResults}
          playerToPick={playerToPick}
          position={playerPosition}
          result={result}
          rematchRequestedBy={rematchRequestedBy}
          socket={socket}  
          winner={winner}
        />
      )}
      { playerToPick == null && !errorMessage && (
        <WaitingForPlayers />
      )}
    </div>
  );

};

export default Flip;
