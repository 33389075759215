import React, { useState } from "react";
import { useHistory } from "react-router-dom";

const ENDPOINT = "https://server.toss4.it/";

const Home = () => {
  
  const [ gameId, setGameId ] = useState("");
  const history = useHistory();
  
  const startGame = async () => {

    const res = await fetch(`${ENDPOINT}/start`);

    if (res) {

      const text = JSON.parse(await res.text());

      setGameId(text.gameId);


      history.push(`/flip/${text.gameId}`);

      // eslint-disable-next-line
      console.log("response", text);
      
    }


  };

  return (
    <>
      <div>This is the app...</div>
      <div>
        <span onClick={startGame}>Start a session</span>
        
        { gameId && (
          <div>
            Game id: { gameId }
          </div>
        )}
      </div>
    </>
  );

};

export default Home;
